import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SubscriptionModel } from '../models/subscription.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  baseUrl = environment.apiUrl;
  menuList!: any[];
  constructor(private http: HttpClient) {}

  SubscriptionInformation(countryCode: string): Observable<any> {
    let parameter = new HttpParams();

    if (countryCode) {
      parameter = parameter.set('CountryCode', countryCode.toString());
    }

    if (countryCode == 'BD') {
      return this.http
        .get<any>(`${this.baseUrl}/api/User/Subscription`, {
          params: parameter,
        })
        .pipe(
          map((products) => ({
            content: products.content.map((product: SubscriptionModel) => ({
              yearlyDiscountPer: product.yearlyDiscountPer,
              subscriptionDetailId: product.subscriptionDetailId,
              subscriptionPckgMasterId: product.subscriptionPckgMasterId,
              moduleId: product.moduleId,
              moduleName: product.moduleName,
              isDefault: product.isDefault,
              monthlyPrice: product.monthlyBDPrice,
              yearlyPrice: product.yearlyBDPrice,
              countryCode: countryCode,
            })),
            isSuccess: products.isSuccess,
            message: products.message,
            code: products.code,
          }))
        );
    } else {
      return this.http
        .get<any>(`${this.baseUrl}/api/User/Subscription`, {
          params: parameter,
        })
        .pipe(
          map((products) => ({
            content: products.content.map((product: SubscriptionModel) => ({
              yearlyDiscountPer: product.yearlyDiscountPer,
              subscriptionDetailId: product.subscriptionDetailId,
              subscriptionPckgMasterId: product.subscriptionPckgMasterId,
              moduleId: product.moduleId,
              moduleName: product.moduleName,
              isDefault: product.isDefault,
              monthlyPrice: product.monthlyUSDPrice,
              yearlyPrice: product.yearlyUSDPrice,
              countryCode: countryCode,
            })),
            isSuccess: products.isSuccess,
            message: products.message,
            code: products.code,
          }))
        );
    }
  }

  EntityWiseSubscription(data: any): Observable<any> {
    console.log('data form service', data);

    return this.http.post<any>(
      `${this.baseUrl}/api/User/EntityWiseSubscription`,
      data
    );
  }

  EntityWiseSubscriptionInvoice(data: any): Observable<any> {
    console.log('data form service', data);

    return this.http.post<any>(
      `${this.baseUrl}/api/User/EntityWiseSubscriptionInvoiceDownload`,
      data,
      { responseType: 'blob' as 'json' }
    );
  }

  SubscriptionCoupon(data: any): Observable<any> {
    console.log('data', data);

    let parameter = new HttpParams();

    if (data.couponCode) {
      parameter = parameter.set('CouponCode', data.couponCode.toString());
    }

    if (data.paymentType) {
      parameter = parameter.set('PaymentType', data.paymentType.toString());
    }

    return this.http.get<any>(`${this.baseUrl}/api/User/SubscriptionCoupon`, {
      params: parameter,
    });
  }

  PaymentTypeInfo(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/User/PaymentTypeInfo`);
  }

  EntitywiseSubscriptionMaster(masterId: number): Observable<any> {
    let parameter = new HttpParams();

    if (masterId) {
      parameter = parameter.set('MasterId', masterId.toString());
    }
    return this.http.get<any>(
      `${this.baseUrl}/api/User/EntitywiseSubscriptionMaster`,
      { params: parameter }
    );
  }

  Organization(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/User/Organization`);
  }
  //#region get floor type
  getOutletInfo(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/Outlet/GetOutletList`);
  }

  GetSubscriptionModuleStatus(
    menuId: number,
    outletId: number
  ): Observable<any> {
    let parameter = new HttpParams();

    if (menuId) {
      parameter = parameter.set('moduleId', menuId.toString());
    }

    if (outletId) {
      parameter = parameter.set('outletId', outletId.toString());
    }
    return this.http.get<any>(
      `${this.baseUrl}/api/Subscription/GetSubscriptionModuleStatus`,
      {
        params: parameter,
      }
    );
  }
  //#endregion

  GetSubscriptionModuleStatusByPath(path: string, outletId: number): boolean {
    console.log('path', path);

    return false;
  }
  //#endregion

  //#region New
  GetSubscriptionStatus(outletId: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/api/Subscription/GetSubscriptionStatus`,
      {
        params: { outletId: outletId.toString() },
      }
    );
  }
  //#endregion

  //#region check global subscription status
  GetSubscriptionGlobalStatus(menuId: number): Observable<any> {
    let parameter = new HttpParams();

    if (menuId) {
      parameter = parameter.set('moduleId', menuId.toString());
    }
    console.log('menuId', menuId);

    return this.http.get<any>(
      `${this.baseUrl}/api/Subscription/GetSubscriptionGlobalStatus`,
      {
        params: parameter,
      }
    );
  }
  //#endregion

  GetEntitywiseSubscriptionMasterByEntity(): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/api/User/GetEntitywiseSubscriptionMasterByEntity`
    );
  }

  SaveSubscriptionData(data: any): Observable<any> {
    console.log('data form service', data);

    return this.http.post<any>(
      `${this.baseUrl}/api/Subscription/SaveSubscriptionData`,
      data
    );
  }
  //#region Renew Subscription

  renewOutlet(outletId: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/api/Subscription/GetOutletRenew?outletId=${outletId} `
    );
  }

  getEntityWiseOutletSubscription(): Observable<any> {
    return this.http.get<any>(
      `${this.baseUrl}/api/Subscription/GetEntityWiseOutletSubscription`
    );
  }

  private outletRenewListSource = new BehaviorSubject<any[]>([]);
  outletRenewList$ = this.outletRenewListSource.asObservable();

  setOutletRenewList(data: any[]) {
    this.outletRenewListSource.next(data);
  }

  private selectedModulesSource = new BehaviorSubject<any[]>([]);
  selectedModules$ = this.selectedModulesSource.asObservable();

  updateSelectedModules(modules: any[]) {
    this.selectedModulesSource.next(modules);
  }

  getSelectedModules() {
    return this.selectedModulesSource.value;
  }

  renewSubscriptionData(data: any): Observable<any> {
    console.log('data form service', data);
    return this.http.post<any>(
      `${this.baseUrl}/api/Subscription/RenewSubscriptionData`,
      data
    );
  }

  //#endregion
}
